:root {
  --clr-1: #ff6c72;
  --txt-clr: #fff;
  --btn-clr: #e75f63;
  --btn-clr-hover: #eb4d52;
}

body {
  background-color: var(--clr-1);
  color: var(--txt-clr);
  max-width: 768px;
  margin: 25px auto;
}

.babyTrackr__logo {
  font-size: 5em !important;
}

.App {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;
}

.babyTrackr__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 5px;
}

.babyTrackr__wrapper__item {
  background: #e75f63;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 1px;
  transition: background 0.5s ease-in-out;
  border: 1px solid #f38285;
  flex: 1 1 45%;
  height: 100%;
}

.babyTrackr__wrapper__item:hover {
  background: var(--btn-clr-hover);
}

.babyTrackr__wrapper__item > span {
  padding: 5px;
}

.clean-input {
  border: unset;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  width: 100%;
  margin: 10px 0;
  text-align: left;
  text-align: left;
  min-height: 30px;
  cursor: pointer;
}

.loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  display: none;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  color: #000;
  z-index: 99999;
}

.loading-screen-icon {
  animation-name: loadingGif;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes loadingGif {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
